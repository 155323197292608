'use strict';

var $ = require('jquery');
var validator = require('validator');

var Gift = function() {
    var self = this;

    this.requestSuccess = function(res) {
        // console.log(res);
        if (res == 1) {
            window.location = '/sikeres-regisztracio';
        } else {
            var resObj = JSON.parse(res);
            $('div.error_backdrop').css('display', 'flex').find('span.email_space').html(resObj.email);
            // console.log(JSON.parse(res));
        }
    };
    
    this.requestFail = function(error) {
        console.log(error);
    };

    this.transformData = function(arr) {
        return arr.reduce(function(obj, item) {
            if (item.name !== 'email_again') {
                switch (item.name) {
                    case 'privacy_accepted':
                    case 'terms_accepted':
                    case 'nl_signup':
                        obj[item.name] = item.value === 'on' ? 1 : 0;
                        break;
                    default:
                        obj[item.name] = item.value;
                        break;
                }
            }
            return obj;
        }, {});
    };

    this.validateNotMobilePhone = function(number) {
        var regExp = /^[0-9]{1}[/\-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4}$/im;

        return regExp.test(number);
    };

    this.validateField = function(input) {
        var id = $(input).attr('id');
        var value = $(input).val();

        switch (id) {
            case 'last_name':
                return !validator.isEmpty(value);
            case 'first_name':
                return !validator.isEmpty(value);
            case 'email':
                return !validator.isEmpty(value) && validator.isEmail(value) && validator.matches(value, $('input#email_again').val());
            case 'email_again':
                return !validator.isEmpty(value) && validator.isEmail(value) && validator.matches(value, $('input#email').val());
            case 'phone':
                return !validator.isEmpty(value) && (validator.isMobilePhone('+36' + value, 'hu-HU') || self.validateNotMobilePhone(value));
            case 'zip_code':
                return !validator.isEmpty(value) && validator.isPostalCode(value, 'HU');
            case 'city':
                return !validator.isEmpty(value);
            case 'address':
                return !validator.isEmpty(value);
            case 'privacy_accepted':
                return $(input).prop('checked');
            case 'terms_accepted':
                return $(input).prop('checked');
            case 'nl_signup':
                return $(input).prop('checked');
        }
    };

    // this.generateErrorMsg = function(input) {
    //     var inputId = $(input).attr('id');
    //     switch (inputId) {
    //         case 'email':
                
    //             break;
    //     }
    // };

    this.showErrorMessage = function(input) {
        if ($(input).attr('type') === 'checkbox') {
            $(input).addClass('error');
        } else {
            $(input).parents('.form_group').children('.error_message').show();
        }
    };
    
    this.hideErrorMessage = function(input) {
        if ($(input).attr('type') === 'checkbox') {
            $(input).removeClass('error');
        } else {
            $(input).parents('.form_group').children('.error_message').hide();
        }
    };

    this.checkboxCheck = function(elem) {
        if (!self.validateField(elem)) {
            self.showErrorMessage(elem);
        } else {
            self.hideErrorMessage(elem);
        }
        // var isAllValid = true;
        // $('input[type=checkbox]').each(function() {
            
        //     isAllValid = isAllValid && self.validateField(this);
        // });
        // return isAllValid;
    };

    this.allInputCheck = function() {
        var everyInputValid = true;
        $('input').not('input[type=submit]').each(function() {
            if (!self.validateField(this)) {
                self.showErrorMessage(this);
            }
            everyInputValid = everyInputValid && self.validateField(this);
        });
        return everyInputValid;
    };

    this.sendForm = function(data) {
        $.ajax({
            type: 'POST',
            url: '/api/index.php',
            // contentType: 'application/json',
            // dataType: 'json',
            data: data,
            success: self.requestSuccess,
            error: self.requestFail
        });
    };

    $(document).ready(function() {
        $('.signup_form').submit(function(e) {
            e.preventDefault();
            if (self.allInputCheck()) {
                var obj = self.transformData($(this).serializeArray());
                // console.log(obj);
                self.sendForm(obj);
            }
        });

        $('input[type=checkbox]').change(function() {
            self.checkboxCheck(this);
        });

        $('input').not('input[type=submit]').keyup(function() {
            var isValid = self.validateField(this);
            // console.log(isValid);
            if (!isValid) {
                $(this).removeClass('valid').addClass('error');
                self.showErrorMessage(this);
            } else {
                $(this).removeClass('error').addClass('valid');
                self.hideErrorMessage(this);
            }
            // console.log($(this).val());
        });

        $('div.close').on('click', function() {
            $('div.error_backdrop').hide();
        });
        
        // $('div.error_backdrop').on('click', function() {
        //     $('div.error_backdrop').hide();
        // });
    });
};

module.exports = Gift;