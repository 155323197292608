// Main javascript entry point
// Should handle bootstrapping/starting application

'use strict';

global.jQuery = require('jquery');
var $ = require('jquery');
var Swiper = require('swiper');
require('jquery-cookiebar');

var Home = require('./home');
var Header = require('../_modules/header/header');
var Capsule = require('./kavekapszula');
var Gift = require('./ajandek-kapszula');

$(function() {
  $(document).ready(function() {
    $.cookieBar({
      message: 'A Jacobs Douwe Egberts körültekintően kezeli az adatokat. Az oldal a jobb felhasználói élmény érdekében cookie-kat használ (<a href="/suti-iranyelvunk/" style="text-decoration: underline; color: inherit;">Cookie Policy</a>). Bármikor beállíthatja, megváltoztathatja vagy letilthatja a cookie-k használatatát internetes böngészőjének cookie-k használatára vonatkozó menüjében.<br>',
      acceptButton: true,
      acceptText: 'Elfogadom',
      policyButton: true,
      policyText: 'Adatvédelmi Szabályzat',
      policyURL: '/adatvedelmi-szabalyzat',
      fixed: true,
      bottom: true,
      zindex: '150'
    });
  });
  new Home();
  new Header();
  new Capsule();
  new Gift();
});