'use strict';

var $ = require('jquery');
var Swiper = require('swiper');

var Capsule = function() {
    $(document).ready(function() {
        $('img.open_icon').on('click', function() {
            if ($(this).parent().siblings('p').hasClass('opened_text')) {
                $(this).parent().siblings('p').hide();
            } else {
                $(this).parent().siblings('p').show();
            }
            $(this).toggleClass('opened');
            $(this).parent().siblings('p').toggleClass('opened_text');
            // $('div.showcase_box p').toggleClass('opened');
        });
    
        var productCarousel = new Swiper('.products_container', {
            // direction: 'horizontal',
            slidesPerView: 5,
            // spaceBetween: 30,
            breakpoints: {
            // when window width is <= 320px
                320: {
                    slidesPerView: 1,
                    spaceBetween: 10
                },
                480: {
                    slidesPerView: 1,
                    spaceBetween: 10
                },
                850: {
                    slidesPerView: 2
                },
                // when window width is <= 480px
                1070: {
                    slidesPerView: 3
                },
                // when window width is <= 640px
                1340: {
                    slidesPerView: 4
                }
            },
            pagination: {
                el: '.swiper-pagination',
                type: 'bullets',
                bulletElement: 'span',
                bulletClass: 'dot',
                bulletActiveClass: 'active_slide',
                clickable: true
            },
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            }
        });
        
        var showcaseCarousel = new Swiper('.showcase_cont_mobile', {
            // direction: 'horizontal',
            slidesPerView: 1,
            // spaceBetween: 30,
            // breakpoints: {
            // // when window width is <= 320px
            //     320: {
            //         slidesPerView: 1,
            //         spaceBetween: 10
            //     },
            //     // when window width is <= 480px
            //     1070: {
            //         slidesPerView: 3
            //     },
            //     // when window width is <= 640px
            //     1340: {
            //         slidesPerView: 4
            //     }
            // },
            // pagination: {
            //     el: '.swiper-pagination',
            //     type: 'bullets',
            //     bulletElement: 'span',
            //     bulletClass: 'dot',
            //     bulletActiveClass: 'active_slide',
            //     clickable: true
            // },
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            }
        });
    });
};

module.exports = Capsule;