'use strict';

var $ = require('jquery');
var Swiper = require('swiper');

var Home = function() {
    $(document).ready(function() {
        var swiper = new Swiper('.top_slider', {
            // Optional parameters
            direction: 'horizontal',
            slidesPerView: 1,
            // spaceBetween: 50,
            // loop: true,

            // autoplay: {
			// 	delay: 5000,
			// 	disableOnInteraction: false
            // },
            
            // effect: 'coverflow',
            // coverflowEffect: {
            //     rotate: 50,
            //     stretch: 0,
            //     depth: 100,
            //     modifier: 1,
            //     slideShadows : true
            // },
    
            // If we need pagination
            pagination: {
                el: '.swiper-pagination',
                type: 'bullets',
                bulletElement: 'span',
                bulletClass: 'dot',
                bulletActiveClass: 'active_slide',
                clickable: true
            },
    
            // Navigation arrows
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            }
        });

        var productCarousel = new Swiper('.products_container', {
            // direction: 'horizontal',
            slidesPerView: 5,
            // spaceBetween: 30,
            breakpoints: {
            // when window width is <= 320px
                610: {
                    slidesPerView: 1
                    // spaceBetween: 10
                },
                810: {
                    slidesPerView: 2
                    // spaceBetween: 10
                },
                // when window width is <= 480px
                1070: {
                    slidesPerView: 3
                    // spaceBetween: 20
                },
                // when window width is <= 640px
                1340: {
                    slidesPerView: 4
                    // spaceBetween: 50
                }
            },
            pagination: {
                el: '.swiper-pagination',
                type: 'bullets',
                bulletElement: 'span',
                bulletClass: 'dot',
                bulletActiveClass: 'active_slide',
                clickable: true
            },

            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            }
        });
    });
};

module.exports = Home;