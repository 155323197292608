'use strict';

var $ = require('jquery');

var Header = function() {
    var _this = this;
    var timeOut = null;
    var mobileMenuOpened = false;
    var pathName = window.location.pathname.split('/')[1];
    var productSubpageName = window.location.pathname.split('/')[2];
    var pages = [
        'kavekapszula',
        'mindent-a-kaverol',
        'termekek',
        'kompatibilitas',
        'kaves-desszertek',
        'ajandekkapszula'
    ];

    var productSubPages = [
        'a-kavekeszites-muveszete',
        'a-kostolas-muveszete',
        'ajandekkapszula-regisztracio',
        'ajandekkapszula-sikeres-regisztracio'
    ];

    this.addActiveClass = function(currentPage) {
        if (pathName === 'termekek' && currentPage) {
            $('ul.dropdown_list a').each(function() {
                if ($(this).attr('href').split('/')[2] === currentPage) {
                    $(this).addClass('active_dropdown');
                }
            });
        }
        $('ul.menu a.hover').each(function() {
            if ($(this).attr('href')) {
                if ($(this).attr('href').split('/')[1] === currentPage || (currentPage === '/' && $(this).attr('href') === '/')) {
                    $(this).addClass('active');
                }
            } else {
                if ($(this).data('href').split('/')[1] === currentPage || (currentPage === '/' && $(this).data('href') === '/')) {
                    $(this).addClass('active');
                }
            }
        });
    };

    this.mouseIn = function() {
        $('ul.dropdown_list').show();
        clearTimeout(timeOut);
    };
    
    this.mouseOut = function() {
        timeOut = setTimeout(function() {
            $('ul.dropdown_list').hide();
        }, 300);
    };

    this.animateMobileMenu = function() {
        if ($('div.together-container_mobil').css('display') === 'none') {
            $('div.together-container_mobil').css('display', 'flex');
        } else {
            $('div.together-container_mobil').css('display', 'none');
        }
    };

    // this.checkIfProductsPage = function() {
    //     if (pathName === pages[0]) {
    //         // console.log($('header nav.navbar.light').css('background'))
    //         if (mobileMenuOpened) {
    //             $('header nav.navbar.light').css('background', '#ffffff');
    //             $('header nav.navbar.dark').css('background', '#ffffff');
    //         } else {
    //             $('header nav.navbar.light').css('background', 'transparent');
    //             $('header nav.navbar.dark').css('background', 'transparent');
    //         }
    //     }
    // };

    // *********
    // EVENTS
    // *********

    $('li.dropdown_container').hover(this.mouseIn, this.mouseOut);
    
    $('ul.dropdown_list').hover(this.mouseIn, this.mouseOut);

    $(window).on('scroll', function(e) {
        var scrollPos = $(window).scrollTop();

        // if (window.innerWidth >= 960) {
            if (scrollPos !== 0) {
                $('.Logo').addClass('animate');
                $('.navbar').addClass('fixed');
                $('section').first().addClass('extra_margin_top');
            } else {
                $('.Logo').removeClass('animate');
                $('.navbar').removeClass('fixed');
                $('section').first().removeClass('extra_margin_top');
            }
        // }

    });

    $('img.MMenu').on('click', function() {
        mobileMenuOpened = !mobileMenuOpened;
        // _this.checkIfProductsPage();
        _this.animateMobileMenu();
    });

    // *********
    // EVENTS END
    // *********

    $(document).ready(function() {
        switch (pathName) {
            case pages[0]:
                _this.addActiveClass(pages[0]);
                break;
            case pages[1]:
                _this.addActiveClass(pages[1]);
                break;
            case pages[2]:
                _this.addActiveClass(pages[2]);
                break;
            case pages[3]:
                _this.addActiveClass(pages[3]);
                break;
            case pages[4]:
                _this.addActiveClass(pages[4]);
                break;
            case pages[5]:
                _this.addActiveClass(pages[5]); 
                break;    
            default:
                _this.addActiveClass('/');
                break;
        }
        
        switch (productSubpageName) {
            case productSubPages[0]:
                _this.addActiveClass(productSubPages[0]);
                break;
            case productSubPages[1]:
                _this.addActiveClass(productSubPages[1]);
                break;
            case productSubPages[2]:
                _this.addActiveClass(productSubPages[2]);
                break;
            case productSubPages[3]:
                _this.addActiveClass(productSubPages[3]);
                break;
            case productSubPages[4]:
                _this.addActiveClass(productSubPages[4]);
                break;
            case productSubPages[5]:
                _this.addActiveClass(productSubPages[5]);
                break;
            default:
                break;
        }
    });
};

module.exports = Header;